@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.monterrat {
	font-family: "Montserrat", sans-serif;
}

.navOpen {
	animation: navOpen 0.5s forwards;
}

/** animation from w-0 to w-full and forward*/
@keyframes navOpen {
	0% {
		max-height: 0;
	}
	100% {
		max-height: 100vh;
	}
}

@keyframes anvil {
	0% {
	  transform: scale(1) translateY(0px);
	  opacity: 0;
	  box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
	  transform: scale(0.96) translateY(10px);
	  opacity: 0;
	  box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
	  transform: scale(1) translateY(0px);
	  opacity: 1;
	  box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
  }
  .popup-content {
	-webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }